<template>
  <div class="page-banners-detail">
    <Loading v-if="loading" :count="6"></Loading>
    <el-descriptions v-else :column="1">
      <el-descriptions-item label="标题(中文)">
        {{ detail.title }}
      </el-descriptions-item>
      <el-descriptions-item label="标题(英文)">
        {{ detail.titleEn }}
      </el-descriptions-item>
      <el-descriptions-item label="图片(中文)">
        <el-image
          style="width: 100px; height: 100px"
          :src="detail.imgUrl"
          :preview-src-list="[detail.imgUrl]"
        ></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="图片(英文)">
        <el-image
          style="width: 100px; height: 100px"
          :src="detail.imgUrl"
          :preview-src-list="[detail.imgUrlEn]"
        ></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="跳转类型">
        <span v-if="detail.type === 0">不跳转</span>
        <span v-if="detail.type === 1">跳转文章</span>
        <span v-if="detail.type === 2">自定义url</span>
      </el-descriptions-item>
      <el-descriptions-item label="跳转链接">
        {{ detail.directUrl }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import Loading from '@/components/loading'

export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      detail: null
    }
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsBanner.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.detail = res
    }
  }
}
</script>

<style lang="scss" scoped>
.page-banners-detail {
  ::v-deep {
    .el-descriptions-item__container {
      min-height: 40px;
      line-height: 40px;
    }
    .el-descriptions-item__label {
      width: 120px;
      text-align: right;
    }
  }
}
</style>
